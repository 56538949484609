export const delegatorsContainerId = 'delegators'
export const debondingContainerId = 'debonding'
export const eventsContainerId = 'events'
export const transactionsContainerId = 'transactions'
export const metadataContainerId = 'metadata'
export const inventoryContainerId = 'inventory'
export const codeContainerId = 'code'
export const holdersContainerId = 'holders'
export const nftTokenTransfersContainerId = 'nftTokenTransfers'
export const nftCollectionContainerId = 'nftCollection'
export const transfersContainerId = 'transfers'
export const tokenTransfersContainerId = 'tokenTransfers'
export const tokenContainerId = 'tokens'
export const instancesContainerId = 'instances'
